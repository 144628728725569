<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel
            title="Laporan Pertanggungjawaban Bendahara Penerimaan SKPD"
            class="panel panel-success"
        >
            <!-- Judul -->
            <div class="py-3">
                <b-row>
                    <b-col>
                        <h4>LAPORAN PENERIMAAN PER RINCIAN OBJEK</h4>
                        <h4>BUKU PEMBANTU PER RINCIAN OBJEK PENERIMAAN</h4>
                    </b-col>
                    <b-col>
                        <div class="float-right">
                            <span class="px-2">
                                <i class="fa fa-info-circle fa-2x"></i>
                            </span>
                            <span class="h4"
                                >FORM FILTER PENERIMAAN PER RINCIAN OBJEK</span
                            >
                        </div>
                    </b-col>
                </b-row>
            </div>
            <hr />
            <!-- end of judul -->

            <!-- form data -->
            <form action="#">
                <div class="form-group row">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >SKPD <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-10">
                                <v-select
                                    :options="suratOptions"
                                    placeholder="Pilih UPT"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >SKPD / UPT <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-10">
                                <v-select
                                    :options="suratOptions"
                                    placeholder="Pilih Bulan"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Periode <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-5">
                                <v-select
                                    :options="suratOptions"
                                    placeholder="Pilih Bulan"
                                ></v-select>
                            </div>
                            <div class="col-md-5">
                                <v-select
                                    :options="suratOptions"
                                    placeholder="Pilih Tahun"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="pt-2">
                    <b-row>
                        <b-col>
                            <div>
                                <span class="px-2">
                                    <b-button variant="primary"
                                        ><i class="fa fa-eye"></i>
                                        Lihat</b-button
                                    >
                                </span>
                                <span>
                                    <b-button variant="secondary"
                                        ><i class="fa fa-redo-alt"></i>
                                        Reset</b-button
                                    >
                                </span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </form>
            <!-- end of form-->
        </panel>
        <!-- end of panel -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "@/components/npwr/HRetribusi.vue";
import axios from "axios";

export default {
    components: { HRetribusi },
    data() {
        return {
            suratOptions: [],
        };
    },
    computed: {},
    methods: {},
};
</script>

<style scoped>
.custom-border {
    border-top: 1px solid rgb(78, 77, 77);
}
</style>
